<template>
  <div class="vld-parent cyb-login base-page">
    <login-form :hasForm="false" pageclass="darker">
      <template #text-content>
        <div class="p-d-flex p-flex-column p-ai-start p-jc-center page-width p-d-flex-1">
          <div class="content">
            <div class="base-template">
              <h1><span>{{ $t('dzone.soSorry') }}</span></h1>
              <p>{{ $t('dzone.notAvailable') }}</p>
            </div>
          </div>
        </div>
      </template>
    </login-form>
  </div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue';
export default {
  data() {
    return {
    };
  },
  components: {
    LoginForm
  }
};
</script>